import React, { useEffect, useState } from "react";
import {
  CardExpandContainer,
  CardExpandContainerHeader,
  CardExpandContainerHeaderContent,
  CardExpandContainerBody,
  ModalContainerHeaderButtonIcon,
  ModalContainerHeaderButton,
  ButtonWrapper,
} from "./styled";

import Button from "components/Form/Button";
import Input from "components/Form/Input";
import { Update } from "services/campaigns";
import SuccessToast from "components/Toasts/Success";

export default function CardCreators({ closeable, campaign, reload }) {
  const [message, setMessage] = useState("");
  const [sending, setSending] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [disabledCreateCreators, setDisabledCreateCreators] = useState(true);
  const [influencer, setInfluencer] = useState({});
  const [step, setStep] = useState(0);

  const removeInfluencer = (id) => {
    const influencerCopy = structuredClone(influencer);

    Reflect.deleteProperty(influencerCopy, id);

    setInfluencer(influencerCopy);
  };

  const setInfluencerDefense = (id, defense) => {
    const influencerCopy = structuredClone(influencer);

    influencerCopy[id].defense = defense;

    setInfluencer(influencerCopy);
  };

  const action = async () => {
    if (disabled) {
      return;
    }

    setSending(true);

    const influencer = message.split("\n");

    await new Promise((resolve) => setTimeout(resolve, 3000));

    const mockInfluencer = {
      imageUrl: "",
      name: "Marcos",
      profile: "@marcosotop",
      totalFollowers: 100,
      totalViews: 100000,
      socialNetworks: {
        instagram: {
          followers: 1000,
          views: 10000,
        },
        youtube: {
          followers: 1000,
          views: 10000,
        },
        tiktok: {
          followers: 1000,
          views: 10000,
        },
        kawaii: {
          followers: 1000,
          views: 10000,
        },
      },
    };

    const influencers = Object.fromEntries(
      influencer.map(() => {
        const id = crypto.randomUUID();
        return [id, { ...mockInfluencer, id }];
      })
    );

    setInfluencer(influencers);
    setStep(1);

    setSending(false);
  };

  const createCreators = async () => {
    await Update(
      { data: { creators: { ...campaign.creators, ...influencer } } },
      campaign.id
    );

    SuccessToast({ message: "Creators adicionados com sucesso" });

    reload();

    closeable();
  };

  useEffect(() => {
    if (!message) {
      setDisabled(true);
      return;
    }

    setDisabled(false);
  }, [message]);

  useEffect(() => {
    if (
      !influencer ||
      Object.values(influencer).some((influencer) => !influencer.defense)
    ) {
      setDisabledCreateCreators(true);
      return;
    }

    setDisabledCreateCreators(false);
  }, [influencer]);

  return (
    <CardExpandContainer closeable={closeable}>
      <CardExpandContainerHeader>
        <CardExpandContainerHeaderContent></CardExpandContainerHeaderContent>
        {closeable ? (
          <ModalContainerHeaderButton>
            <Button outline nospace onClick={closeable}>
              <ModalContainerHeaderButtonIcon icon={"close"} />
            </Button>
          </ModalContainerHeaderButton>
        ) : null}
      </CardExpandContainerHeader>
      <CardExpandContainerBody>
        {step === 0 && (
          <>
            <h4>Adicionar creators</h4>
            <div>
              <Input
                type={"textarea"}
                label={"Links da conta *"}
                placeholder="Insira em cada linha os links de contas Instagram, YouTube ou Tiktok"
                value={message}
                onChange={(e) => setMessage(e?.target?.value)}
              />
              <ButtonWrapper disabled={disabled}>
                <Button
                  primary
                  loading={sending}
                  onClick={action}
                  marginRight={"12px"}
                >
                  CONTINUAR
                </Button>
              </ButtonWrapper>
            </div>
          </>
        )}

        {step === 1 && (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "start",
              }}
            >
              <img
                src="/images/comeback-arrow.png"
                width={"10px"}
                height={"18px"}
                style={{ cursor: "pointer" }}
                onClick={() => setStep(0)}
              ></img>
              <h4 style={{ margin: 0, marginLeft: "20px" }}>
                Adicionar creators
              </h4>
            </div>
            <div style={{ marginTop: "16px" }}>
              <div
                style={{
                  display: "flex",
                  borderRadius: "8px",
                  padding: "16px",
                  boxShadow: "0px 0px 16px 0px rgba(0, 0, 0, 0.16)",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src="/images/check-purple-white.png"
                  width={"40px"}
                  height={"40px"}
                ></img>
                <div style={{ marginLeft: "16px" }}>
                  <h6 style={{ fontSize: "14px", marginBottom: "4px" }}>
                    Incluir defesas
                  </h6>
                  <p style={{ fontSize: "12px", margin: 0 }}>
                    Para finalizar, inclua uma defesa para cada creator que será
                    adicionado.
                  </p>
                </div>
              </div>
              <ul
                style={{
                  display: "flex",
                  flexDirection: "column",
                  listStyle: "none",
                  padding: 0,
                }}
              >
                {Object.entries(influencer).map(([id, influencer]) => (
                  <li
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "16px",
                      borderRadius: "16px",
                      border: "1px solid rgba(204, 196, 216, 1)",
                      marginTop: "16px",
                    }}
                  >
                    <div
                      onClick={() => removeInfluencer(id)}
                      style={{
                        alignSelf: "end",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "24px",
                        height: "24px",
                        background: "rgba(244, 244, 244, 1)",
                        borderRadius: "32px",
                        marginBottom: "16px",
                        cursor: "pointer",
                      }}
                    >
                      <img
                        src="/icons/close.svg"
                        width={"10px"}
                        height={"10px"}
                        onClick={() => removeInfluencer(id)}
                      ></img>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <img
                          src={
                            influencer.imageUrl ||
                            "https://avatars.githubusercontent.com/u/67443852?s=400&u=3d9aedd86c316a16fb50eef3ae23973cedac9efa&v=4"
                          }
                          width={"48px"}
                          height={"48px"}
                          style={{
                            borderRadius: "32px",
                            marginRight: "8px",
                          }}
                        ></img>
                        <div>
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <h6
                              style={{
                                fontSize: "20px",
                                fontWeight: 700,
                                margin: "0px 0px 4px",
                                marginRight: "8px",
                              }}
                            >
                              {influencer.name}
                            </h6>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "24px",
                                height: "24px",
                                borderRadius: "32px",
                                border: "1.07px solid rgba(204, 196, 216, 1)",
                                marginRight: "4px",
                              }}
                            >
                              <img
                                src={"/images/insta-grey.png"}
                                width={"12px"}
                                height={"12px"}
                              ></img>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "24px",
                                height: "24px",
                                borderRadius: "32px",
                                border: "1.07px solid rgba(204, 196, 216, 1)",
                              }}
                            >
                              <img
                                src={"/images/tiktok-grey.png"}
                                width={"12px"}
                                height={"12px"}
                              ></img>
                            </div>
                          </div>
                          <p style={{ fontSize: "12px" }}>
                            {influencer.profile}
                          </p>
                        </div>
                      </div>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={"/images/heart.png"}
                              width={"16px"}
                              height={"17px"}
                              style={{ marginRight: "4px" }}
                            ></img>
                            <p style={{ fontSize: "14px", margin: 0 }}>
                              Seguidores
                            </p>
                          </div>
                          <p
                            style={{
                              fontSize: "20px",
                              fontWeight: 700,
                              margin: 0,
                              background:
                                "linear-gradient(90.02deg, #AF00E3 11.75%, #FF00D6 80.59%, #FF00D6 81.46%)",
                              "-webkit-text-fill-color": "transparent",
                              "-webkit-background-clip": "text",
                            }}
                          >
                            {influencer.totalFollowers >= 1000
                              ? `${influencer.totalFollowers / 1000}K`
                              : influencer.totalFollowers}
                          </p>
                        </div>

                        <div style={{ marginLeft: "24px" }}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={"/images/button-play.png"}
                              width={"16px"}
                              height={"17px"}
                              style={{ marginRight: "4px" }}
                            ></img>
                            <p
                              style={{
                                fontSize: "14px",
                                margin: 0,
                              }}
                            >
                              Views
                            </p>
                          </div>
                          <p
                            style={{
                              fontSize: "20px",
                              fontWeight: 700,
                              margin: 0,
                              background:
                                "linear-gradient(90.02deg, #AF00E3 11.75%, #FF00D6 80.59%, #FF00D6 81.46%)",
                              "-webkit-text-fill-color": "transparent",
                              "-webkit-background-clip": "text",
                            }}
                          >
                            {influencer.totalViews >= 1000
                              ? `${influencer.totalViews / 1000}K`
                              : influencer.totalViews}
                          </p>
                        </div>
                      </div>
                    </div>
                    <Input
                      type={"textarea"}
                      label="Informar defesa *"
                      placeholder="Digite uma defesa para este creator"
                      labelPadding="0"
                      labelMargin="6px 0px 8px 0px"
                      value={influencer.defense}
                      onChange={(e) =>
                        setInfluencerDefense(id, e?.target?.value)
                      }
                    ></Input>
                  </li>
                ))}
              </ul>
              <ButtonWrapper disabled={disabledCreateCreators}>
                <Button
                  primary
                  loading={sending}
                  onClick={createCreators}
                  marginRight={"12px"}
                >
                  ADICIONAR CREATORS
                </Button>
              </ButtonWrapper>
            </div>
          </>
        )}
      </CardExpandContainerBody>
    </CardExpandContainer>
  );
}
