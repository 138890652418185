import styled from "styled-components";

export const CardHomeContent = styled.div.attrs({})`
  width: 100%;
  height: 190px;
  margin: 16px 0 0;
  padding: 16px 24px;
  border-radius: 16px;
  background: ${(props) => props.theme.palette.colors.white};

  box-shadow: 0px 3px 6px ${(p) => p.theme.palette.colors.shadow};
`;
export const CardHomeContentHeader = styled.div.attrs({})`
  display: flex;
  justify-content: flex-end;
  padding: 0 0 12px;
`;
export const CardHomeContentBody = styled.div.attrs({})``;
export const CardHomeContentBodyTitle = styled.div.attrs({})`
  font-size: 18px;
  color: ${(props) => props.theme.palette.colors.black};
`;
export const CardHomeContentBodyText = styled.p.attrs({})`
  font-size: 48px;
  font-weight: 700;
  margin: 8px 0 14px;

  background: linear-gradient(
    90.02deg,
    ${(props) => props.theme.palette.colors.degradeprimary} 11.75%,
    ${(props) => props.theme.palette.colors.degradesecondary} 80.59%,
    ${(props) => props.theme.palette.colors.degradesecondary} 81.46%
  );
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
`;
